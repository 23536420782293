<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <headerpos></headerpos>
    <div class="page-wrapper ms-0">
      <div class="content">
        <div class="row">
          <!-- desktop and tab -->
          <div class="d-none d-sm-none d-md-none d-lg-block d-xl-block col-lg-8 col-sm-12 tabs_wrapper">
            <div class="page-header d-flex justify-content-between">
              <div class="page-title">
                <h4>Categories </h4>
                <h6>Manage your purchases</h6>
              </div>
              <div class="d-none d-sm-none d-lg-flex d-xl-flex justify-content-end gap-2" style="width:80%">
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Search..." v-model="query">

                </div>
                <a href="javascript:void(0);" class="bg-white p-1 rounded-2 " @click="dailyreportmodal()">
                  <vue-feather type="file-text"></vue-feather>
                  <!-- <span class="badge rounded-pill">1</span> -->
                </a>
                <a href="javascript:void(0);" class="bg-white p-1 rounded-2 " data-bs-toggle="modal"
                  data-bs-target="#buka_kasir_modal" ref="buttonopenmodalbukakasir">
                  <vue-feather type="inbox"></vue-feather>

                </a>
              </div>



            </div>
            <div class="owl-caousel-tab">
              <carousel :wrap-around="false" :settings="settings" :breakpoints="breakpoints">
                <slide class="tabs owl-product border-0" v-for="item in pos" :key="item.id">
                  <li :id="item.posid" :class="item.class" @click="active_id = item.id"
                    @touchstart="active_id = item.id" style="z-index:1000px!important">
                    <div class="product-details ">
                      <div class="h-50">
                        <img :src="item.img" alt="img" style="width:30px!important" />
                      </div>
                      <div class="h-50">
                        <h6>{{ item.name }}</h6>
                      </div>
                    </div>
                  </li>
                </slide>
                <template #addons>
                  <navigation />
                </template>
              </carousel>
            </div>
            <div class="tabs_container">
              <div data-tab="fruits">
                <div class="row">
                  <div class="col-lg-3 col-sm-6 d-flex" v-for="item in data_items_filter" :key="item.id">
                    <div class="productset flex-fill " @click="checkBeforeAddItems(item)">
                      <div class="productsetimg">
                        <img :src="item.gambar" alt="img" class="image-fill" />
                        <!-- <h6>Qty: 5.00</h6> -->
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h6>{{ rupiah(item[rateActive]) }}</h6>
                        <div class="fs-5">{{ item.nama }}</div>
                        <!-- <h5>{{ item.nama_kategori }}</h5> -->


                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product31.jpg" alt="img" />
                        <h6>Qty: 1.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Fruits</h5>
                        <h4>Strawberry</h4>
                        <h6>15.00</h6>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>

            </div>
          </div>
          <div class="d-none d-sm-none d-md-none d-lg-block d-xl-block col-lg-4 col-sm-12">
            <div class="order-list d-flex justify-content-between">
              <div class="orderid ">

                <h4>Order List</h4>

                <!-- <h5>Transaction id : #65565</h5> -->
              </div>
              <div class="btn-pos ">
                <ul>
                  <li>
                    <a class="btn" @click="listTransaction()" data-bs-toggle="modal" data-bs-target="#recents"><img
                        src="../../../assets/img/icons/transcation.svg" alt="img" class="me-1" />
                      Transaction</a>
                  </li>
                </ul>
              </div>
              <!-- <div class="actionproducts">
                <ul>
                  <li>
                    <a href="javascript:void(0);" class="deletebg confirm-text" @click="showAlert"><img
                        src="../../../assets/img/icons/delete-2.svg" alt="img" /></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" data-bs-toggle="dropdown" aria-expanded="false" class="dropset">
                      <img src="../../../assets/img/icons/ellipise1.svg" alt="img" />
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" data-popper-placement="bottom-end">
                      <li>
                        <a href="javascript:void(0);" class="dropdown-item">Action</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="dropdown-item">Another Action</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="dropdown-item">Something Elses</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div> -->
            </div>
            <div class="card card-order">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <a href="javascript:void(0);" class="btn btn-adds" data-bs-toggle="modal"
                      data-bs-target="#create"><i class="fa fa-plus me-2"></i>Add Customer</a>
                  </div>
                  <div class="col-lg-12">
                    <div class="select-split">
                      <div class="select-group w-100">
                        <model-select :options="Walk" placeholder="Pilih Customer" v-model="customer_id">
                        </model-select>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-lg-12">
                    <div class="select-split">
                      <div class="select-group w-100">
                        <model-select :options="marketing_data" placeholder="Pilih Marketing" v-model="marketing_id">
                        </model-select>
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="col-12">
                    <div class="text-end">
                      <a class="btn btn-scanner-set"><img src="../../../assets/img/icons/scanner1.svg" alt="img"
                          class="me-2" />Scan bardcode</a>
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="split-card"></div>
              <div class="card-body pt-0">
                <div class="totalitem">
                  <h4>Total items : {{ products.length }}</h4>
                  <a href="javascript:void(0);" @click="products = []">Clear all</a>
                </div>
                <div class="product-table">
                  <ul class="product-lists" v-for="(item, index) in products" :key="item.id">
                    <li class=" w-100">
                      <div class="productimg">
                        <div class="productimgs">
                          <img :src="item.img" alt="img" />
                        </div>
                        <div class="productcontet  w-100">
                          <div class="d-flex justify-content-start  align-items-center ">
                            <h4 class="">
                              {{ item.name }}
                              <!-- <a href="javascript:void(0);" class="ms-2" data-bs-toggle="modal" data-bs-target="#edit"><img
                                src="../../../assets/img/icons/edit-5.svg" alt="img" /></a> -->
                            </h4>
                          </div>
                          <div class="d-flex justify-content-start  align-items-center ">
                            <h6 class="text-primary" v-if="item.id_marketing > 0">
                              {{ item.nama_marketing }}
                              <!-- <a href="javascript:void(0);" class="ms-2" data-bs-toggle="modal" data-bs-target="#edit"><img
                                src="../../../assets/img/icons/edit-5.svg" alt="img" /></a> -->
                            </h6>
                          </div>
                          <div class="w-100 d-flex justify-content-between  text-end ">
                            <!-- <div class="productlinkset">
                              <h5>{{ item.code }}</h5>
                            </div> -->
                            <div>
                              <div class="increment-decrement ">
                                <div class="input-groups">
                                  <input type="button" value="-" class="button-minus dec button"
                                    @click="decreaseItemsQuantity(item, index)" />
                                  <div class="ps-2 pe-2">{{ item.count }}</div>
                                  <input type="button" value="+" class="button-plus inc button"
                                    @click="increaseItemsQuantity(item, index)" />
                                </div>
                              </div>
                            </div>
                            <div>
                              <div class="d-flex align-items-center gap-2">
                                <div>{{ rupiah(item.rate) }}</div>
                                <div class="mt-2">
                                  <a class="confirm-text fs-6" href="javascript:void(0);"
                                    @click="removeItems(index)"><img src="../../../assets/img/icons/delete-2.svg"
                                      alt="img" style="max-width: none!important; height: 20px; width: 20px;" /></a>
                                </div>
                              </div>
                            </div>

                          </div>

                        </div>
                      </div>
                    </li>

                  </ul>
                </div>
              </div>
              <div class="split-card"></div>
              <div class="card-body pt-0 pb-2">
                <div class="setvalue">
                  <ul>
                    <li>
                      <h5>Subtotal</h5>
                      <h6>{{ rupiah(products.reduce((total, product) => total + parseInt(product.rate), 0)) }}</h6>
                    </li>
                    <li>
                      <a href="javascript:void(0);" class="" data-bs-toggle="modal" data-bs-target="#discount"
                        ref="buttonopenmodaldiscount">
                        <h5>Discount</h5>
                      </a>

                      <h6 class="order-amount">{{ rupiah(discount_amount) }}</h6>
                    </li>
                    <li>

                      <h5>Tax {{ tax_name }}</h5>

                      <!-- <div class="d-flex justify-content-between gap-2">
                        <select class="form-control form-white w-auto" data-placeholder="Pilih tipe" v-model="tax_type"
                          disabled>
                          <option value="persen">%</option>
                          <option value="rupiah">Rp.</option>
                        </select>
                        <div class="form-group mb-0">
                          <input type="number" v-model="tax_rate" @focus="selectAll" placeholder="" />
                        </div>
                      </div> -->
                      <h6 class="order-amount">{{
                        rupiah(tax_amount) }}</h6>
                    </li>
                    <li>

                      <h5>Service Charge</h5>

                      <!-- <div class="d-flex justify-content-between gap-2">
                        <select class="form-control form-white w-auto" data-placeholder="Pilih tipe" v-model="tax_type"
                          disabled>
                          <option value="persen">%</option>
                          <option value="rupiah">Rp.</option>
                        </select>
                        <div class="form-group mb-0">
                          <input type="number" v-model="tax_rate" @focus="selectAll" placeholder="" />
                        </div>
                      </div> -->
                      <h6 class="order-amount">{{
                        rupiah(sc_amount) }}</h6>
                    </li>
                    <li class="total-value">
                      <h5>Total</h5>
                      <h6>{{ rupiah(grand_total) }}</h6>
                    </li>
                  </ul>
                </div>
                <div class="setvaluecash">
                  <div class="row gx-1">
                    <div class="col-md-3 mb-1" v-for="item in data_payment_proses" :key="item.id" :id="item.id">
                      <ul class=" h-100">
                        <li class="border-primary w-100 h-100 ">
                          <a href="javascript:void(0); " class="paymentmethod h-100" :class="item.class"
                            @click="setActivePayment(item)">
                            <div class=" ">
                              <div class="h-50 d-flex justify-content-center align-items-end">
                                <img :src="item.image_url" alt="img" class="me-2" width="30"
                                  v-if="item.image_url != ''" />
                                <div v-else class="">
                                  {{ rupiah(item.deposit) }}
                                </div>
                              </div>
                              <div class="h-50 d-flex justify-content-center align-items-start text-center px-1"
                                style="font-size: 0.7rem;">
                                {{ item.nama }}
                              </div>

                            </div>

                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="d-flex gap-2 ">
                  <div class="btn-totallabel " @click="checkBeforeCheckOut" style="cursor: pointer;width: 80%;">
                    <h5 class="text-white">Checkout</h5>
                    <h6 class="text-white">{{ rupiah(grand_total)
                      }}</h6>
                  </div>
                  <div class="btn-totallabel w-full" stye="" @click="holdCheckOut()"
                    style="cursor: pointer;width: 20%; background-color: #dc3545;">
                    <h5 class="text-white">Save</h5>

                  </div>
                </div>

                <div class="btn-pos ">
                  <ul>
                    <!-- <li>
                      <a class="btn" ><img src="../../../assets/img/icons/pause1.svg" alt="img"
                          class="me-1" />Hold</a>
                    </li> -->
                    <!-- <li>
                      <a class="btn"><img src="../../../assets/img/icons/edit-6.svg" alt="img"
                          class="me-1" />Quotation</a>
                    </li>
                    <li>
                      <a class="btn"><img src="../../../assets/img/icons/trash12.svg" alt="img" class="me-1" />Void</a>
                    </li>
                    <li>
                      <a class="btn"><img src="../../../assets/img/icons/wallet1.svg" alt="img"
                          class="me-1" />Payment</a>
                    </li> -->
                    <!-- <li>
                      <a class="btn" @click="listTransaction()" data-bs-toggle="modal" data-bs-target="#recents"><img
                          src="../../../assets/img/icons/transcation.svg" alt="img" class="me-1" />
                        Transaction</a>
                    </li> -->
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="d-block d-sm-block d-md-block d-lg-none d-xl-none col-lg-4 col-sm-12">
            <div class="order-list page-header ">
              <div class="d-flex d-sm-none d-lg-none d-xl-none justify-content-end w-100">


                <a href="javascript:void(0);" class="bg-white p-1 rounded-2 " @click="dailyreportmodal()">
                  <vue-feather type="file-text"></vue-feather>
                  <!-- <span class="badge rounded-pill">1</span> -->
                </a>

              </div>
              <div class="orderid page-title w-50">
                <h5 class="fw-bold">Select Customer</h5>
                <!-- <h5>Transaction id : #65565</h5> -->
              </div>
              <div class="d-none d-sm-flex d-lg-flex d-xl-flex justify-content-end w-50">


                <a href="javascript:void(0);" class="bg-white p-1 rounded-2 " @click="dailyreportmodal()">
                  <vue-feather type="file-text"></vue-feather>
                  <!-- <span class="badge rounded-pill">1</span> -->
                </a>

              </div>
              <!-- <div class="actionproducts">
                <ul>
                  <li>
                    <a href="javascript:void(0);" class="deletebg confirm-text" @click="showAlert"><img
                        src="../../../assets/img/icons/delete-2.svg" alt="img" /></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" data-bs-toggle="dropdown" aria-expanded="false" class="dropset">
                      <img src="../../../assets/img/icons/ellipise1.svg" alt="img" />
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" data-popper-placement="bottom-end">
                      <li>
                        <a href="javascript:void(0);" class="dropdown-item">Action</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="dropdown-item">Another Action</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="dropdown-item">Something Elses</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div> -->
            </div>
            <div class="card card-order">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <a href="javascript:void(0);" class="btn btn-adds" data-bs-toggle="modal"
                      data-bs-target="#create"><i class="fa fa-plus me-2"></i>Add Customer</a>
                  </div>
                  <div class="col-lg-12">
                    <div class="select-split">
                      <div class="select-group w-100">
                        <model-select :options="Walk" placeholder="Pilih Customer" v-model="customer_id">
                        </model-select>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-lg-12">
                    <div class="select-split">
                      <div class="select-group w-100">
                        <model-select :options="marketing_data" placeholder="Pilih Marketing" v-model="marketing_id">
                        </model-select>
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="col-12">
                    <div class="text-end">
                      <a class="btn btn-scanner-set"><img src="../../../assets/img/icons/scanner1.svg" alt="img"
                          class="me-2" />Scan bardcode</a>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="d-block d-sm-block d-md-block d-lg-none d-xl-none col-lg-8 col-sm-12 tabs_wrapper">

            <div class="page-header">
              <div class="page-title">
                <h4>Categories </h4>
                <h6>Manage your purchases</h6>
              </div>
            </div>



            <div class="owl-caousel-tab">
              <carousel :wrap-around="false" :settings="settings" :breakpoints="breakpoints" @click="test()">
                <slide class="tabs owl-product border-0" v-for="item in pos" :key="item.id">
                  <li :id="item.posid" :class="item.class" @click="active_id = item.id"
                    @touchstart="active_id = item.id" style="z-index:1000px!important">
                    <div class="product-details ">
                      <div class="h-50">
                        <img :src="item.img" alt="img" style="width:30px!important" />
                      </div>
                      <div class="h-50">
                        <h6>{{ item.name }}</h6>
                      </div>
                    </div>
                  </li>
                </slide>
                <template #addons>
                  <navigation />
                </template>
              </carousel>
            </div>
            <div class="tabs_container">
              <div data-tab="fruits">
                <div class="row">
                  <div class="col-lg-3 col-sm-6 d-flex" v-for="item in data_items_filter" :key="item.id">
                    <div class="productset flex-fill " @click="checkBeforeAddItems(item)">
                      <div class="productsetimg">
                        <img :src="item.gambar" alt="img" class="image-fill" />
                        <!-- <h6>Qty: 5.00</h6> -->
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h6>{{ rupiah(item[rateActive]) }}</h6>
                        <div class="fs-5">{{ item.nama }}</div>
                        <!-- <h5>{{ item.nama_kategori }}</h5> -->


                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product31.jpg" alt="img" />
                        <h6>Qty: 1.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Fruits</h5>
                        <h4>Strawberry</h4>
                        <h6>15.00</h6>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>

            </div>
          </div>
          <div class="d-block d-sm-block d-md-block d-lg-none d-xl-none col-lg-4 col-sm-12">
            <div class="order-list d-flex justify-content-between">
              <div class="orderid ">
                <h4>Order List</h4>
                <!-- <h5>Transaction id : #65565</h5> -->
              </div>
              <div class="btn-pos ">
                <ul>
                  <li>
                    <a class="btn" @click="listTransaction()" data-bs-toggle="modal" data-bs-target="#recents"><img
                        src="../../../assets/img/icons/transcation.svg" alt="img" class="me-1" />
                      Transaction</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="card card-order">

              <div class="split-card"></div>
              <div class="card-body pt-0">
                <div class="totalitem">
                  <h4>Total items : {{ products.length }}</h4>
                  <a href="javascript:void(0);" @click="products = []">Clear all</a>
                </div>
                <div class="product-table">
                  <ul class="product-lists" v-for="(item, index) in products" :key="item.id">
                    <li class=" w-100">
                      <div class="productimg">
                        <div class="productimgs">
                          <img :src="item.img" alt="img" />
                        </div>
                        <div class="productcontet  w-100">
                          <div class="d-flex justify-content-start  align-items-center ">
                            <h4 class="">
                              {{ item.name }}
                              <!-- <a href="javascript:void(0);" class="ms-2" data-bs-toggle="modal" data-bs-target="#edit"><img
                                src="../../../assets/img/icons/edit-5.svg" alt="img" /></a> -->
                            </h4>
                          </div>
                          <div class="d-flex justify-content-start  align-items-center ">
                            <h6 class="text-primary" v-if="item.id_marketing > 0">
                              {{ item.nama_marketing }}
                              <!-- <a href="javascript:void(0);" class="ms-2" data-bs-toggle="modal" data-bs-target="#edit"><img
                                src="../../../assets/img/icons/edit-5.svg" alt="img" /></a> -->
                            </h6>
                          </div>
                          <div class="w-100 d-flex justify-content-between  text-end ">
                            <!-- <div class="productlinkset">
                              <h5>{{ item.code }}</h5>
                            </div> -->
                            <div>
                              <div class="increment-decrement ">
                                <div class="input-groups">
                                  <input type="button" value="-" class="button-minus dec button"
                                    @click="decreaseItemsQuantity(item, index)" />
                                  <div class="ps-2 pe-2">{{ item.count }}</div>
                                  <input type="button" value="+" class="button-plus inc button"
                                    @click="increaseItemsQuantity(item, index)" />
                                </div>
                              </div>
                            </div>
                            <div>
                              <div class="d-flex align-items-center gap-2">
                                <div>{{ rupiah(item.rate) }}</div>
                                <div class="mt-2">
                                  <a class="confirm-text fs-6" href="javascript:void(0);"
                                    @click="removeItems(index)"><img src="../../../assets/img/icons/delete-2.svg"
                                      alt="img" style="max-width: none!important; height: 20px; width: 20px;" /></a>
                                </div>
                              </div>
                            </div>

                          </div>

                        </div>
                      </div>
                    </li>

                  </ul>
                </div>
              </div>
              <div class="split-card"></div>
              <div class="card-body pt-0 pb-2">
                <div class="setvalue">
                  <ul>
                    <li>
                      <h5>Subtotal</h5>
                      <h6>{{ rupiah(total) }}</h6>
                    </li>
                    <!-- <li>
                      <h5>Tax</h5>
                      <h6>5.00$</h6>
                    </li> -->
                    <li class="total-value">
                      <h5>Total</h5>
                      <h6>{{ rupiah(grand_total) }}</h6>
                    </li>
                  </ul>
                </div>
                <div class="setvaluecash">
                  <div class="row gx-1">
                    <div class="col-md-3 mb-1" v-for="item in data_payment_proses" :key="item.id" :id="item.id">
                      <ul class=" h-100">
                        <li class="border-primary w-100 h-100 ">
                          <a href="javascript:void(0); " class="paymentmethod h-100" :class="item.class"
                            @click="setActivePayment(item)">
                            <div class=" ">
                              <div class=" d-flex justify-content-center align-items-end" style="height:70%!important">
                                <img :src="item.image_url" alt="img" class="me-2" width="30"
                                  v-if="item.image_url != ''" />
                                <div v-else class="">
                                  {{ rupiah(item.deposit) }}
                                </div>
                              </div>
                              <div class="h-50 d-flex justify-content-center align-items-start text-center px-1"
                                style="font-size: 0.7rem;">
                                {{ item.nama }}
                              </div>

                            </div>

                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="btn-totallabel " stye="" @click="checkBeforeCheckOut" style="cursor: pointer;">
                  <h5 class="text-white">Checkout</h5>
                  <h6 class="text-white">{{ rupiah(grand_total)
                    }}</h6>
                </div>
                <!-- <div class="btn-pos bg-danger">
                  <ul>
                    <li>
                      <a class="btn"><img src="../../../assets/img/icons/pause1.svg" alt="img" class="me-1" />Hold</a>
                    </li>
                    <li>
                      <a class="btn"><img src="../../../assets/img/icons/edit-6.svg" alt="img"
                          class="me-1" />Quotation</a>
                    </li>
                    <li>
                      <a class="btn"><img src="../../../assets/img/icons/trash12.svg" alt="img" class="me-1" />Void</a>
                    </li>
                    <li>
                      <a class="btn"><img src="../../../assets/img/icons/wallet1.svg" alt="img"
                          class="me-1" />Payment</a>
                    </li>
                    <li>
                      <a class="btn" data-bs-toggle="modal" data-bs-target="#recents"><img
                          src="../../../assets/img/icons/transcation.svg" alt="img" class="me-1" />
                        Transaction</a>
                    </li>
                  </ul>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <a href="javascript:void(0);" class="btn btn-adds" data-bs-toggle="modal" data-bs-target="#confirmation"
    ref="buttonopenmodalconfirmation" hidden><i class="fa fa-plus me-2"></i></a>
  <a href="javascript:void(0);" class="btn btn-adds" data-bs-toggle="modal" data-bs-target="#pilihmarketing"
    ref="buttonopenmodalpilihmarketing" hidden><i class="fa fa-plus me-2"></i></a>
  <a href="javascript:void(0);" class="btn btn-adds" data-bs-toggle="modal" data-bs-target="#dailyreport"
    ref="buttonopenmodaldailyreport" hidden><i class="fa fa-plus me-2"></i></a>


  <posmodal @data_customer_insert="handleEmployeeInsertData" @konfirmasi="handleKonfirmasi" :customer="customer"
    :total="grand_total" :paymentmethod="payment_method" :marketingdata="marketing_data"
    @marketing_id="handlePilihMarketing" @notes="handleNotes" :holddata="hold_data" @data_hold_item="handleHoldData">
  </posmodal>


  <div class="modal fade" id="discount" tabindex="-1" aria-labelledby="discount" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div>
            <h5 class="modal-title">Insert Discount
            </h5>
            <span for="">insert the amount of discount</span>
          </div>

          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" ref="buttonclosemodaldiscount">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <div class="d-flex justify-content-between gap-2">
                <select class="form-control form-white w-auto" data-placeholder="Pilih tipe" v-model="discount_type">
                  <option value="rupiah">Rp.</option>
                  <option value="persen">%</option>
                </select>
                <div class="form-group mb-0">
                  <input type="number" v-model="calculated_discount" @focus="selectAll" placeholder="" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 mt-3">
            <button class="btn btn-submit me-2" data-bs-dismiss="modal" type="button">Submit</button>
            <!-- <a class="btn btn-cancel" data-bs-dismiss="modal" type="button">Cancel</a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <form @submit.prevent="bukaKasir">
    <div class="modal fade" id="buka_kasir_modal" tabindex="-1" aria-labelledby="buka_kasir_modal"
      data-bs-keyboard="false" data-bs-backdrop="static" aria-hidden="true">
      <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div>
              <h5 class="modal-title">Open Cashier
              </h5>
              <span for="">insert the initial capital</span>
            </div>

            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"
              ref="buttonclosemodaldiscount" hidden>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12">

                <div class="form-group mb-0">
                  <label>Modal Awal</label>
                  <input type="text" v-model="currentData.modal_awal" placeholder="" />
                </div>

              </div>
            </div>
            <div class="col-lg-12 mt-3">
              <button class="btn btn-submit me-2" type="submit">Submit</button>
              <!-- <a class="btn btn-cancel" data-bs-dismiss="modal" type="button">Cancel</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

</template>

<style scoped>
.active {
  border: 1px solid #235d9f !important;
  color: #235d9f !important;
}

.productsetimg {
  position: relative;
  min-height: 200px !important;
  overflow: hidden;
}

.image-fill {
  width: 100%;
  height: 100%;
  max-height: 200px !important;
  object-fit: cover;
}

.form-group input[type="number"] {
  border: 1px solid rgba(145, 158, 171, 0.32);
  height: 40px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #637381;
  padding: 10px 15px;
  border-radius: 5px;
}
</style>

<script>
import { ref } from "vue";

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import Cookies from "js-cookie";
import axios from "axios";

import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, rupiah, goPage, AlertBottom } from "@/assets/js/function";
import { ModelSelect } from 'vue-search-select';

export default {

  setup() {

    //const count = ref(1);
    const increment = async (index) => {
      products.value[index].count++;
    };
    const decrement = async (index) => {
      products.value[index].count--;
    };
    const products = ref([
      // { id: 1, name: "Pineapple", img: "product30.jpg", count: 0 },
    ]);


    return {
      // products,
      increment,
      decrement,
    };
  },
  data() {
    return {
      active_id: 0,
      customer_id: null,
      marketing_id: null,
      query: "",

      Product: ["Product", "Barcode"],
      Walk: [],
      settings: {
        itemsToShow: 2,
        snapAlign: "center",
      },

      customer: "",
      total: 0,
      grand_total: 0,
      payment_method: "",

      breakpoints: {
        575: {
          itemsToShow: 4,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 6,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 8,
          snapAlign: "start",
        },
      },
      help: [
        // {
        //   id: 1,
        //   img: "product62.png",
        //   posid: "fruits",
        //   name: "Fruits",
        // },

      ],
      data_items: [],
      marketing_data: [],
      hold_data: [],
      products: [
        // { id: 1, name: "Pineapple", img: "product30.jpg", count: 0 },
      ],
      return_amount: 0,
      payment_amount: 0,

      //rate default that active when customer select
      rateActive: "rate",
      //var to store data payment
      data_payment: [],
      //var to save what the payment method that active
      payment_active_id: 0,
      //var to save is the deposit or not if is than the customer cannot buy another item that not deposit
      buy_deposit: false,
      //var to save is selected record marketing
      marketing_item_selected: [],
      notes: "",

      discount_type: "persen",
      calculated_discount: "0",
      discount_amount: 0,
      tax_amount: 0,
      tax_rate: 0,
      tax_name: "PPN",
      tax_type: "persen",
      sc_amount: 0,
      sc_rate: 0,
      sc_type: "persen",
      profiles: [],

      //data kasir pos
      currentData: {
        kode_kasir: "",
        modal_awal: "",
      },

    };
  },
  mounted() {

    $(".tabs li").click(function () {
      var $this = $(this);
      var $theTab = $(this).attr("active_id");

      if ($this.hasClass("active")) {
        // do nothing
      } else {
        $this
          .closest(".tabs_wrapper")
          .find(".tabs li, .tabs_container .tab_content")
          .removeClass("active");
        $(
          '.tabs_container .tab_content[data-tab="' +
          $theTab +
          '"], tabs li[id="' +
          $theTab +
          '"]'
        ).addClass("active");
      }
    });
  },
  created() {

    this.token = Cookies.get("token_refresh");
    this.config = {
      headers: { Authorization: `Bearer ` + this.token },
      timeout: 30000,
    }
    this.checkCategory();

  },
  computed: {

    pos() {
      if (this.query == '') {
        return this.help.map((item) => {
          item.class = this.active_id === item.id ? "active" : "";
          return item;
        });
      } else {
        return [];
      }

    },
    data_payment_proses() {
      return this.data_payment.map((item) => {
        item.class = this.payment_active_id === item.id ? "active" : "";

        return item;
      });
    },
    data_items_filter() {
      // console.log(this.data_items)
      if (this.query == '') {
        return this.data_items.filter(item =>
          item.id_kategori.toString().toLowerCase().includes(this.active_id)
        );
      } else {

        return this.data_items.filter(item =>
          item.nama.toString().toLowerCase().includes(this.query) ||
          item.code.toString().toLowerCase().includes(this.query)
        );
      }

    }
  },
  watch: {
    customer_id(newValue, oldValue) {
      // console.log('Selected Value:', newValue);
      // Do something with the selected value
      const customerIndex = this.Walk.findIndex((item) => item.id === this.customer_id);
      const paymentDepositIndex = this.data_payment.findIndex((item) => item.nama == 'DEPOSIT');

      // console.log('tes :',customerIndex)

      if (this.Walk[customerIndex].amount_deposit > 0) {
        if (paymentDepositIndex > -1) {
          const deposit = { id: 'deposit', nama: 'DEPOSIT', image_url: "", deposit: this.Walk[customerIndex].amount_deposit }
          this.data_payment[paymentDepositIndex] = deposit;
        } else {
          this.data_payment.push({ id: 'deposit', nama: 'DEPOSIT', image_url: "", deposit: this.Walk[customerIndex].amount_deposit })
        }

        this.$nextTick(() => {
          // console.log(document.getElementById("deposit"));
          document.getElementById("deposit").classList.remove('col-md-3');
          document.getElementById("deposit").classList.add('col-md-12');
        });



      } else {
        const payment_index = this.data_payment.findIndex((item) => item.id == 'deposit');

        if (payment_index > -1) {
          this.data_payment.splice(payment_index, 1)
        }

      }

      if (customerIndex > -1) {
        if (this.Walk[customerIndex].rate > 0) {
          this.rateActive = 'rate' + this.Walk[customerIndex].rate;
        } else {
          this.rateActive = 'rate';
        }
      } else {
        this.rateActive = 'rate';
      }


      this.products = [];
    },
    'calculated_discount': {
      handler(newValue) {
        if (this.calculated_discount == '' || (newValue > 100 && this.discount_type == 'persen')
          || newValue < 0 || (this.discount_type == 'rupiah' && newValue > this.total)) {

          this.calculated_discount = 0;
        }
        if (this.discount_type == 'rupiah') {

          this.discount_amount = newValue;
        } else if (this.discount_type == 'persen' && this.total > 0) {
          this.discount_amount = parseInt(newValue) * (parseInt(this.total)) / 100;
        } else {
          this.discount_amount = 0;
        }


        this.grand_total = this.total - this.discount_amount + this.tax_amount;

      }
    },
    // 'tax_rate': {
    //   handler(newValue) {
    //     if ((newValue > 100 && this.tax_type == 'persen') || newValue < 0) {
    //       this.tax_rate = 0;
    //     }

    //     if (this.tax_type == 'persen' && this.total > 0) {
    //       this.tax_amount = parseInt(newValue) * (parseInt(this.total)) / 100;
    //     } else {
    //       this.tax_amount = 0;
    //     }
    //     this.grand_total = this.total - this.discount_amount + this.tax_amount;
    //   }
    // }
  },
  methods: {
    rupiah,

    selectAll(event) {
      // Select all text when input field is focused
      event.target.select();
    },
    dailyreportmodal() {

      const openButton = this.$refs.buttonopenmodaldailyreport;
      openButton.click();
    },
    handleKonfirmasi(item) {
      if (item == 'YA') {
        this.checkOut();
      }
    },
    handleNotes(item) {
      this.notes = item.notes;
      this.return_amount = item.return_amount;
      this.payment_amount = item.payment_amount;

    },
    handlePilihMarketing(item) {

      if (item > -1) {
        this.addItems(this.marketing_item_selected, item);
      }
    },
    handleEmployeeInsertData(item) {

      // Update the data received from the child component
      let item_prepare = { ...item, value: item.id, text: item.fullname + ' / ' + item.whatsapp };
      this.Walk.push(item_prepare);

      // console.log(item_prepare)

      // console.log('Updated Walk Array:', this.Walk);

      this.customer_id = item.id;


      // console.log('New Customer ID:', this.customer_id);
    },
    setActivePayment(item) {
      this.payment_active_id = item.id;
      this.payment_method = item.nama;
    },
    updateTax(newValue) {
      if ((newValue > 100 && this.tax_type == 'persen') || newValue < 0) {
        this.tax_rate = 0;
      }

      if (this.tax_type == 'persen' && this.total > 0) {
        this.tax_amount = parseInt(newValue) * (parseInt(this.total)) / 100;
      } else {
        this.tax_amount = 0;
      }
      this.grand_total = this.total - this.discount_amount + this.tax_amount + this.sc_amount;
    },
    updateSc(newValue) {
      if ((newValue > 100 && this.sc_type == 'persen') || newValue < 0) {
        this.sc_rate = 0;
      }

      if (this.sc_type == 'persen' && this.total > 0) {
        this.sc_amount = parseInt(newValue) * (parseInt(this.total)) / 100;
      } else {
        this.sc_amount = 0;
      }
      this.grand_total = this.total - this.discount_amount + this.sc_amount + this.sc_amount;
    },
    updateGrandTotal() {
      if (this.products.length == 0) {
        this.total = 0;
      } else {
        this.total = this.products.reduce((total, product) => total + parseInt(product.rate), 0);
      }
      this.updateTax(this.profiles.tax_percent);
      this.updateSc(this.profiles.sc_percent);
      this.grand_total = this.total - this.discount_amount + this.tax_amount + this.sc_amount;

    },
    checkBeforeAddItems(record) {
      if (this.customer_id == null) {
        AlertPopup('error', '', 'Silakan pilih costumer terlebih dahulu ', '2000', true)
        return false;
      }

      if (record.ismarketing == 'AKTIF') {
        const openButton = this.$refs.buttonopenmodalpilihmarketing;
        openButton.click();
        this.marketing_item_selected = record;
      } else {
        this.addItems(record);
        this.query = '';
      }

    },
    addItems(record, id_marketing) {

      const customerIndex = this.Walk.findIndex((item) => item.id === this.customer_id);
      const rateNumber = this.Walk[customerIndex].rate;
      let rateAttribute = '';
      if (rateNumber > 0) {
        rateAttribute = 'rate' + rateNumber;
      } else {
        rateAttribute = 'rate';
      }


      const index = this.data_items.findIndex((item) => item.id === record.id);
      const index2 = this.products.findIndex((item) => item.id === record.id);


      if (this.data_items[index].isdeposit == 'AKTIF' && (this.products.length == 0 || this.buy_deposit == true)) {
        this.buy_deposit = true;
        if (index2 > -1) {
          this.products[index2].count++;
          this.products[index2].rate = parseInt(this.data_items[index][rateAttribute]) + parseInt(this.products[index2].rate);
        } else {
          let nama_marketing = "";
          if (id_marketing > -1) {
            const indexMarketing = this.marketing_data.findIndex((item) => item.id === id_marketing);
            if (indexMarketing > -1) {
              nama_marketing = this.marketing_data[indexMarketing].nama;
            } else {
              nama_marketing = "";
            }
          } else {
            id_marketing = 0;
          }

          this.products.push({ id: record.id, name: '[DEPOSIT] ' + record.nama, img: record.gambar, count: 1, rate: record[rateAttribute], isdeposit: record.isdeposit, ismarketing: record.ismarketing, id_marketing: id_marketing, nama_marketing: nama_marketing });
        }
      } else if (this.data_items[index].isdeposit == 'TIDAK' && this.buy_deposit == true) {
        AlertPopup("error", "", 'item dalam keranjang adalah item deposit dan tidak bisa digabungkan dengan item regular', 3000, true);
      } else if (this.data_items[index].isdeposit == 'TIDAK' && this.buy_deposit == false) {
        this.buy_deposit = false;
        if (index2 > -1 && id_marketing == undefined) {
          this.products[index2].count++;
          this.products[index2].rate = parseInt(this.data_items[index][rateAttribute]) + parseInt(this.products[index2].rate);
        } else {
          let nama_marketing = "";
          if (id_marketing > -1) {
            const indexMarketing = this.marketing_data.findIndex((item) => item.id === id_marketing);

            if (indexMarketing > -1) {

              nama_marketing = this.marketing_data[indexMarketing].fullname;
            } else {
              nama_marketing = "";
            }
          } else {
            id_marketing = 0;
          }

          this.products.push({ id: record.id, name: record.nama, img: record.gambar, count: 1, rate: record[rateAttribute], isdeposit: record.isdeposit, ismarketing: record.ismarketing, id_marketing: id_marketing, nama_marketing: nama_marketing });
        }
      } else if (this.data_items[index].isdeposit == 'AKTIF' && this.buy_deposit == false) {
        AlertPopup("error", "", 'item dalam keranjang adalah item regular dan tidak bisa digabungkan dengan item deposit', 3000, true);
      }

      this.cekBuyDeposit();


      //update total
      this.updateGrandTotal();


    },
    increaseItemsQuantity(record, index_item) {
      const customerIndex = this.Walk.findIndex((item) => item.id === this.customer_id);

      const rateNumber = this.Walk[customerIndex].rate;
      let rateAttribute = '';
      if (rateNumber > 0) {
        rateAttribute = 'rate' + rateNumber;
      } else {
        rateAttribute = 'rate';
      }

      const index = this.data_items.findIndex((item) => item.id === record.id);
      // const index2 = this.products.findIndex((item) => item.id === record.id);

      // index_item = 

      if (index_item > -1) {
        this.products[index_item].count++;
        this.products[index_item].rate = parseInt(this.data_items[index][rateAttribute]) + parseInt(this.products[index_item].rate);
      }

      //update total
      this.updateGrandTotal();
    },
    decreaseItemsQuantity(record, index_item) {
      const customerIndex = this.Walk.findIndex((item) => item.id === this.customer_id);

      const rateNumber = this.Walk[customerIndex].rate;
      let rateAttribute = '';
      if (rateNumber > 0) {
        rateAttribute = 'rate' + rateNumber;
      } else {
        rateAttribute = 'rate';
      }

      const index = this.data_items.findIndex((item) => item.id === record.id);
      // const index_item = this.products.findIndex((item) => item.id === record.id);

      if (index_item > -1) {
        this.products[index_item].count--;
        this.products[index_item].rate = parseInt(this.products[index_item].rate) - parseInt(this.data_items[index][rateAttribute]);
        if (this.products[index_item].count == 0) {
          this.products.splice(index_item, 1);
        }
      }

      //update total
      this.updateGrandTotal();
      this.cekBuyDeposit();
    },
    cekBuyDeposit() {
      const index = this.products.findIndex((item) => item.isdeposit === 'AKTIF');
      if (index > -1) {
        this.buy_deposit = true;
      } else {
        this.buy_deposit = false;
      }
    },
    removeItems(index_item) {
      // const index = this.products.findIndex((item) => item.id === record.id);
      // if (index > -1) {
      // }
      this.products.splice(index_item, 1);
      //update total
      this.updateGrandTotal();
      this.cekBuyDeposit();


    },
    showAlert() {
      // Use sweetalert2
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#FF9F43",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.");
        }
      });
    },
    checkCategory() {
      ShowLoading();
      // let formData = new FormData();
      // const currentDate = new Date();
      // const formattedDate = currentDate.toISOString().slice(0, 10);

      // formData.append("periode", formattedDate);

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'pos_index';
      } else {
        this.api = base_url + 'pos_index';
      }

      axios
        .post(this.api, '', this.config)
        .then((response) => {

          if (response.data.status == true) {
            this.data_items = response.data.data.item;

            //masukan pajak   
            // console.log(response.data.dataprofiles);
            this.profiles = response.data.data.profiles[0];

            response.data.data.kategori.forEach((item, index) => {
              if (index == 0) {
                this.active_id = item.id;
              }
              let item_prepare = { id: item.id, posid: item.id, name: item.nama, parent: item.parent, img: item.image_url, };
              this.help.push(item_prepare)
            });

            this.Walk.push({ id: 0, text: 'Walk in', rate: "0", value: 0 });
            response.data.data.customer.forEach((item, index) => {
              let item_prepare = { ...item, value: item.id, text: item.fullname + ' / ' + item.whatsapp };
              this.Walk.push(item_prepare)
            });

            response.data.data.marketing.forEach((item, index) => {
              let item_prepare = { ...item, value: item.id, text: item.fullname };
              this.marketing_data.push(item_prepare)
            });
            this.data_payment = response.data.data.payments;

            CloseLoading();
            if (response.data.data.profiles[0].fitur_kas == 'AKTIF') {
              this.KasIndex();
            }

          }


          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {

          ErrorConnectionTimeOut(error);
        });
    },
    KasIndex() {
      ShowLoading();

      if (Cookies.get('kode_kasir') != undefined && Cookies.get('kode_kasir') != '') {
        CloseLoading();
        this.currentData.kode_kasir == Cookies.get('kode_kasir');
        return true;
      }

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'pos_kasir_index';
      } else {
        this.api = base_url + 'pos_kasir_index';
      }

      axios
        .post(this.api, '', this.config)
        .then((response) => {
          // console.log(response);
          if (response.data.status == true) {

            if (response.data.data.kode_kasir == '') {
              CloseLoading();
              this.$refs.buttonopenmodalbukakasir.click();
            } else {
              Cookies.set('kode_kasir', response.data.kode_kasir);
            }

          }


          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
            CloseLoading();
          }
        })
        .catch((error) => {

          ErrorConnectionTimeOut(error);
        });
    },
    bukaKasir() {
      ShowLoading();

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'pos_buka_kasir';
      } else {
        this.api = base_url + 'pos_buka_kasir';
      }

      // ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      axios
        .post(this.api, formData, this.config)
        .then((response) => {

          if (response.data.status == true) {
            Cookies.set('kode_kasir', response.data.data.kode_kasir);
            AlertBottom(response.data.data.message);
            this.$refs.buttonclosemodaldiscount.click();
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
            CloseLoading();
          }
        })
        .catch((error) => {

          ErrorConnectionTimeOut(error);
          // CloseLoading();
        });
    },
    checkBeforeCheckOut() {


      // if (this.marketing_id == 0) {
      //   AlertPopup('error', '', 'Silakan pilih marketing terlebih dahulu ', '2000', true)
      //   return false;
      // }
      if (this.products.length == 0) {
        AlertPopup('error', '', 'Silakan pilih item terlebih dahulu ', '2000', true)
        return false;
      }
      if (this.payment_active_id == 0) {
        AlertPopup('error', '', 'Silakan pilih metode pembayaran terlebih dahulu ', '2000', true)
        return false;
      }
      const paymentIndex = this.data_payment.findIndex((list) => list.id === this.payment_active_id);

      this.total = this.products.reduce((total, product) => total + parseInt(product.rate), 0);

      if (paymentIndex > -1 && this.payment_method == 'DEPOSIT' && this.data_payment[paymentIndex].deposit < this.total) {
        AlertPopup('error', '', 'Mohon maaf sisa deposit tidak cukup, Silakan pilih metode pembayaran yang lain', '3000', true)
        return false;

      }

      if (this.customer_id > 0) {
        const customerIndex = this.Walk.findIndex((list) => list.id === this.customer_id);

        if (customerIndex > -1) {
          this.customer = this.Walk[customerIndex].fullname;
        }

      } else {
        this.customer = 'Walk in';
      }

      const check_method = this.payment_method;
      this.payment_method = '';
      this.payment_method = check_method;






      const openButton = this.$refs.buttonopenmodalconfirmation;
      openButton.click();


    },
    checkOut() {
      let formData = new FormData();
      const currentDate = new Date().toISOString().split('T')[0];


      this.total = this.products.reduce((total, product) => total + parseInt(product.rate), 0);
      let item_id = [];
      let item_qty = [];
      let item_rate = [];
      let item_discount_type = [];
      let item_discount_amount = [];
      let item_is_tax = [];
      let item_name = [];
      let item_calculated_discount = [];
      let item_total = [];
      let item_isdeposit = [];
      let item_id_marketing = [];


      this.products.forEach((item) => {
        item_id.push(item.id)
        item_qty.push(item.count)

        const indexItem = this.data_items.findIndex((list) => list.id === item.id);

        item_rate.push(this.data_items[indexItem][this.rateActive]);
        item_discount_type.push(0);
        item_discount_amount.push(0);
        item_is_tax.push(0);
        item_name.push(this.data_items[indexItem].nama);
        item_calculated_discount.push(0);
        item_total.push(item.rate);
        item_isdeposit.push(this.data_items[indexItem].isdeposit);
        item_id_marketing.push(item.id_marketing);

      });



      formData.append("invoice_date", currentDate);
      formData.append("due_date", currentDate);
      formData.append("terms", 0);
      formData.append("po_number", "");
      formData.append("id_customer", this.customer_id);
      formData.append("subtotal", this.total);
      formData.append("discount_type", this.discount_type);
      formData.append("discount_amount", this.discount_amount);
      formData.append("tax_type", this.tax_type);
      formData.append("tax_rate", this.tax_amount);
      formData.append("sc_rate", this.sc_amount);
      formData.append("tax_name", 'PPN');
      formData.append("payment_date", currentDate);
      formData.append("payment_amount", this.payment_amount);
      formData.append("return_amount", this.return_amount);
      formData.append("payment_method", this.payment_method);
      formData.append("payment_notes", '');
      formData.append("unpaid_amount", 0);
      formData.append("grand_total", this.grand_total);
      formData.append("notes", this.notes);
      formData.append("payment_info", '');
      formData.append("bank_name", '');
      formData.append("bank_account_number", '');
      formData.append("bank_account_name", '');
      formData.append("calculated_discount", 0);

      formData.append("item_id", item_id);
      formData.append("item_qty", item_qty);
      formData.append("item_rate", item_rate);
      formData.append("item_discount_type", item_discount_type);
      formData.append("item_discount_amount", item_discount_amount);
      formData.append("item_is_tax", item_is_tax);
      formData.append("item_name", item_name);
      formData.append("item_calculated_discount", item_calculated_discount);
      formData.append("item_total", item_total);
      formData.append("item_isdeposit", item_isdeposit);
      formData.append("item_id_marketing", item_id_marketing);

      formData.append("id_marketing", this.marketing_id);
      formData.append("kode_kasir", this.currentData.kode_kasir);



      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }
      // return false;


      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'invoices_insert';
      } else {
        this.api = base_url + 'invoices_insert';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {

            // console.log(response);
            localStorage.setItem('slug', response.data.data[0].slug);

            if (localStorage.getItem('hold_data') != null || localStorage.getItem('hold_data') != undefined) {
              const hold_data = JSON.parse(localStorage.getItem('hold_data'));

              if (hold_data.length > 0) {
                const holdDataIndex = hold_data.findIndex((item) => item.id === this.customer_id);
                hold_data.splice(holdDataIndex, 1);
                localStorage.setItem('hold_data', JSON.stringify(hold_data));

              }
            }

            goPage('view');
            CloseLoading();

          }


          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {

          ErrorConnectionTimeOut(error);
        });
    },
    holdCheckOut() {
      // console.log('test');
      if (this.products.length == 0) {
        AlertPopup('error', '', 'Silakan pilih item terlebih dahulu ', '2000', true)
        return false;
      }
      // if (this.payment_active_id == 0) {
      //   AlertPopup('error', '', 'Silakan pilih metode pembayaran terlebih dahulu ', '2000', true)
      //   return false;
      // }

      const paymentIndex = this.data_payment.findIndex((list) => list.id === this.payment_active_id);

      this.total = this.products.reduce((total, product) => total + parseInt(product.rate), 0);

      if (paymentIndex > -1 && this.payment_method == 'DEPOSIT' && this.data_payment[paymentIndex].deposit < this.total) {
        AlertPopup('error', '', 'Mohon maaf sisa deposit tidak cukup, Silakan pilih metode pembayaran yang lain', '3000', true)
        return false;

      }

      if (this.customer_id > 0) {
        const customerIndex = this.Walk.findIndex((list) => list.id === this.customer_id);

        if (customerIndex > -1) {
          this.customer = this.Walk[customerIndex].fullname;
        }

      } else {
        this.customer = 'Walk in';
      }

      const currentDate = new Date().toISOString().split('T')[0];


      this.total = this.products.reduce((total, product) => total + parseInt(product.rate), 0);
      let item_id = [];
      let item_qty = [];
      let item_rate = [];
      let item_discount_type = [];
      let item_discount_amount = [];
      let item_is_tax = [];
      let item_name = [];
      let item_calculated_discount = [];
      let item_total = [];
      let item_isdeposit = [];
      let item_id_marketing = [];


      this.products.forEach((item) => {
        item_id.push(item.id)
        item_qty.push(item.count)

        const indexItem = this.data_items.findIndex((list) => list.id === item.id);

        item_rate.push(this.data_items[indexItem][this.rateActive]);
        item_discount_type.push(0);
        item_discount_amount.push(0);
        item_is_tax.push(0);
        item_name.push(this.data_items[indexItem].nama);
        item_calculated_discount.push(0);
        item_total.push(item.rate);
        item_isdeposit.push(this.data_items[indexItem].isdeposit);
        item_id_marketing.push(item.id_marketing);

      });


      const holdData = {
        invoice_date: currentDate,
        id_customer: this.customer_id,
        customer: this.customer,
        subtotal: this.total,
        discount_type: this.discount_type,
        discount_amount: this.discount_amount,
        tax_type: this.tax_type,
        tax_rate: this.tax_amount,
        tax_name: 'PPN',
        payment_date: currentDate,
        payment_amount: this.total,
        payment_method: this.payment_method,
        payment_notes: '',
        unpaind_amount: 0,
        grand_total: this.grand_total,
        notes: this.notes,
        payment_info: '',
        bank_name: '',
        bank_account_number: '',
        calculated_discount: 0,
        item_id: item_id,
        item_qty: item_qty,
        item_rate: item_rate,
        item_discount_type: item_discount_type,
        item_discount_amount: item_discount_amount,
        item_is_tax: item_is_tax,
        item_name: item_name,
        item_calculated_discount: item_calculated_discount,
        item_total: item_total,
        item_isdeposit: item_isdeposit,
        item_id_marketing: item_id_marketing,
        id_marketing: this.marketing_id,
        kode_kasir: this.currentData.kode_kasir,
        products: this.products,
        id_payment: this.payment_active_id,
      }

      if (localStorage.getItem('hold_data') != null) {
        let hold_data_array = JSON.parse(localStorage.getItem('hold_data'));

        const holdDataIndex = hold_data_array.findIndex((item) => item.id_customer == this.customer_id);

        if (holdDataIndex > -1) {
          hold_data_array[holdDataIndex] = holdData;
          AlertBottom('Hold Data Updated');
        } else {
          hold_data_array.push(holdData);
          AlertBottom('Hold Data Inserted');
        }
        localStorage.setItem('hold_data', JSON.stringify(hold_data_array));

      } else {

        let hold_data_array = [];
        hold_data_array.push(holdData);
        localStorage.setItem('hold_data', JSON.stringify(hold_data_array));
        AlertBottom('Hold Data inserted');

      }

      this.customer_id = 0;
      this.payment_active_id = 0;
      this.$nextTick(() => {
        // console.log(document.getElementById("deposit"));
        this.products = [];
        this.updateGrandTotal();
      });


    },
    listTransaction() {
      // console.log(JSON.parse(localStorage.getItem('hold_data')));
      // this.hold_data = [];
      this.hold_data = JSON.parse(localStorage.getItem('hold_data'));
      // if (localStorage.getItem('hold_data') != undefined && localStorage.getItem('hold_data') != null) {
      //   this.hold_data = JSON.parse(localStorage.getItem('hold_data'));
      // } else {
      //   this.hold_data = [];
      // }




    },

    handleHoldData(item) {

      this.customer_id = item.id_customer;
      this.payment_active_id = item.id_payment;
      this.$nextTick(() => {
        // console.log(document.getElementById("deposit"));
        this.products = item.products;
        this.updateGrandTotal();
      });


    },

  },
  components: {
    Carousel,
    Slide,
    Navigation,
    ModelSelect

  },
  name: "pos",
};
</script>
